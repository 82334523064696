import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import Moon01Icon from '@untitled-ui/icons-react/build/esm/Moon01';
import SunIcon from '@untitled-ui/icons-react/build/esm/Sun';
import type { PaletteMode } from '~/theme';
import type { FC, ReactElement } from 'react';
interface Option {
  label: string;
  value: PaletteMode;
  icon: ReactElement;
}
const options: Option[] = [{
  label: 'Light',
  value: 'light',
  icon: <SvgIcon fontSize="small">
				<SunIcon />
			</SvgIcon>
}, {
  label: 'Dark',
  value: 'dark',
  icon: <SvgIcon fontSize="small">
				<Moon01Icon />
			</SvgIcon>
}];
interface OptionsColorSchemeProps {
  onChange?: (value: PaletteMode) => void;
  value?: PaletteMode;
}
export const OptionsColorScheme: FC<OptionsColorSchemeProps> = props => {
  const {
    onChange,
    value
  } = props;
  return <Stack spacing={1} data-sentry-element="Stack" data-sentry-component="OptionsColorScheme" data-sentry-source-file="options-color-scheme.tsx">
			<Typography color="text.secondary" variant="overline" data-sentry-element="Typography" data-sentry-source-file="options-color-scheme.tsx">
				Color Scheme
			</Typography>
			<Stack alignItems="center" direction="row" flexWrap="wrap" gap={2} data-sentry-element="Stack" data-sentry-source-file="options-color-scheme.tsx">
				{options.map(option => <Chip icon={option.icon} key={option.value} label={option.label} onClick={() => onChange?.(option.value)} sx={{
        borderColor: 'transparent',
        borderRadius: 1.5,
        borderStyle: 'solid',
        borderWidth: 2,
        ...(option.value === value && {
          borderColor: 'primary.main'
        })
      }} />)}
			</Stack>
		</Stack>;
};