import { StoreApi, UseBoundStore } from 'zustand';

import { CreateFileInput, FileProductInput, UpdateFileInput } from './__generated/gql/graphql';

export type State = {
	isLatest: boolean;
	token: string | null;
	companyId: string | null;
	till: {
		isOpen: boolean;
		selectedCategoryId: string | null;
		selectedProductId: string | null;
		tempProductInput: FileProductInput | null;
		createFileInput: Omit<CreateFileInput, 'sortingIndex'> | null;
		updateFileInput: Required<Omit<UpdateFileInput, 'sortingIndex'>> | null;
	};
};

export type Actions = {
	resetApp: (data?: Partial<State>) => void;
	setIsLatest: (data: State['isLatest']) => void;
	setToken: (data: State['token']) => void;
	setCompanyId: (data: State['companyId']) => void;
	resetTill: () => void;
	setTillIsOpen: (data: State['till']['isOpen']) => void;
	setTillSelectedCategoryId: (data: State['till']['selectedCategoryId']) => void;
	setTillSelectedProductId: (data: State['till']['selectedProductId']) => void;
	updateTillTempProductInput: (data: Partial<State['till']['tempProductInput']>) => void;
	updateTillCreateFileInput: (data: Partial<State['till']['createFileInput']>) => void;
	updateTillUpdateFileInput: (data: Partial<State['till']['updateFileInput']>) => void;
};

// Helpers to autogenerate selectors
type WithSelectors<S> = S extends { getState: () => infer T }
	? S & { use: { [K in keyof T]: () => T[K] } }
	: never;

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
	const store = _store as WithSelectors<typeof _store>;
	store.use = {};
	for (const k of Object.keys(store.getState())) {
		(store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
	}

	return store;
};
