import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { Actions, State, createSelectors } from '~/types/store';

const INITIAL_STATE: State = {
	isLatest: true,
	token: null,
	companyId: null,
	till: {
		isOpen: false,
		selectedCategoryId: null,
		selectedProductId: null,
		tempProductInput: null,
		createFileInput: null,
		updateFileInput: null,
	},
};

const $useStore = create<State & Actions>()(
	persist(
		(set, get) => ({
			...INITIAL_STATE,
			resetApp: (extra) => set({ ...INITIAL_STATE, ...extra }),
			setIsLatest: (data) => set({ isLatest: data }),
			setToken: (data) => set({ token: data }),
			setCompanyId: (data) => set({ companyId: data }),
			resetTill: () => set({ till: INITIAL_STATE.till }),
			setTillIsOpen: (data) => set({ till: { ...get().till, isOpen: data } }),
			setTillSelectedCategoryId: (data) => set({ till: { ...get().till, selectedCategoryId: data } }),
			setTillSelectedProductId: (data) => set({ till: { ...get().till, selectedProductId: data } }),
			updateTillTempProductInput: (data) =>
				set({
					till: {
						...get().till,
						tempProductInput:
							data === null
								? null
								: {
										...get().till.tempProductInput!,
										...data,
								  },
					},
				}),
			updateTillCreateFileInput: (data) =>
				set({
					till: {
						...get().till,
						createFileInput:
							data === null
								? null
								: {
										...get().till.createFileInput!,
										...data,
								  },
					},
				}),
			updateTillUpdateFileInput: (data) =>
				set({
					till: {
						...get().till,
						updateFileInput:
							data === null
								? null
								: {
										...get().till.updateFileInput!,
										...data,
								  },
					},
				}),
		}),
		{
			name: 'served-erp-store',
			storage: createJSONStorage(() => window.sessionStorage),
		}
	)
);

const selectors = createSelectors($useStore);
const { use, setState: $setState, getState: $getState } = selectors;

export const useStore = $useStore;
export const setState = $setState;
export const getState = $getState;
export const useIsLatest = () => use.isLatest();
export const useToken = () => use.token();
export const useCompanyId = () => use.companyId();
export const useResetApp = () => use.resetApp();
export const useSetIsLatest = () => use.setIsLatest();
export const useSetToken = () => use.setToken();
export const useSetCompanyId = () => use.setCompanyId();
export const useTill = () => use.till();
export const useResetTill = () => use.resetTill();
export const useTillIsOpen = () => useStore((store) => store.till.isOpen);
export const useTillSelectedCategoryId = () => useStore((store) => store.till.selectedCategoryId);
export const useTillSelectedProductId = () => useStore((store) => store.till.selectedProductId);
export const useTillTempProductInput = () => useStore((store) => store.till.tempProductInput);
export const useTillCreateFileInput = () => useStore((store) => store.till.createFileInput);
export const useTillUpdateFileInput = () => useStore((store) => store.till.updateFileInput);
export const useSetTillIsOpen = () => use.setTillIsOpen();
export const useSetTillSelectedCategoryId = () => use.setTillSelectedCategoryId();
export const useSetTillSelectedProductId = () => use.setTillSelectedProductId();
export const useUpdateTillTempProductInput = () => use.updateTillTempProductInput();
export const useUpdateTillCreateFileInput = () => use.updateTillCreateFileInput();
export const useUpdateTillUpdateFileInput = () => use.updateTillUpdateFileInput();
