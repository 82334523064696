// Remove if simplebar is not used
import 'simplebar-react/dist/simplebar.min.css';
import '~/global.css';
// Remove if locales are not used
import '~/locales/i18n';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Head from 'next/head';
import { useEventListener } from 'usehooks-ts';
import { RTL } from '~/components/rtl';
import { SettingsButton } from '~/components/settings/settings-button';
import { SettingsDrawer } from '~/components/settings/settings-drawer';
import { Toaster } from '~/components/toaster';
import { SettingsConsumer, SettingsProvider } from '~/contexts/settings';
import { useNprogress } from '~/hooks/use-nprogress';
import { createTheme } from '~/theme';
import { createEmotionCache } from '~/utils/create-emotion-cache';
import { QueryProvider } from '~/providers/QueryProvider';
import { AppProvider } from '~/providers/AppProvider';
import { AuthConsumer, AuthProvider } from '~/contexts/auth';
import { SplashScreen } from '~/components/splash-screen';
import { I18nConsumer, I18nProvider } from '~/contexts/i18n';
import { AppUpdateChecker } from '~/components/app-update-checker';
import { OfflineDialog } from '~/components/offline-dialog';
import { useResetApp } from '~/store';
import { ErrorBoundary } from '~/components/error-boundary';
import '~/libs/firebase';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import type { EmotionCache } from '@emotion/react';
const clientSideEmotionCache = createEmotionCache();
export interface CustomAppProps extends AppProps {
  Component: NextPage;
  emotionCache?: EmotionCache;
}
const CustomApp = (props: CustomAppProps) => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps
  } = props;
  const resetApp = useResetApp();
  useNprogress();
  useEventListener('unload', () => {
    resetApp();
    window.$queryClient?.clear?.();
  });
  const getLayout = Component.getLayout ?? (page => page);
  return <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-component="CustomApp" data-sentry-source-file="_app.tsx">
			<Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
				<title>Served ERP</title>
				<meta name="viewport" content="initial-scale=1, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
			</Head>
			<I18nProvider data-sentry-element="I18nProvider" data-sentry-source-file="_app.tsx">
				<I18nConsumer data-sentry-element="I18nConsumer" data-sentry-source-file="_app.tsx">
					{i18n => <QueryProvider>
							<AppProvider>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<AuthProvider>
										<AuthConsumer>
											{auth => <SettingsProvider>
													<SettingsConsumer>
														{settings => {
                        const theme = createTheme({
                          colorPreset: settings.colorPreset,
                          contrast: settings.contrast,
                          direction: settings.direction,
                          paletteMode: settings.paletteMode,
                          responsiveFontSizes: settings.responsiveFontSizes
                        });

                        // Prevent guards from redirecting
                        const showSlashScreen = auth.isLoading || !auth.isInitialized || !settings.isInitialized || !i18n.isInitialized;
                        return <ThemeProvider theme={theme}>
																	<ErrorBoundary appName="ERP Web App" id="1265989573398495292" token="0ALOPFEX-zho-SLMbuRS_4iR7OETyLZskajHhKQ8rhklZbnI6xk0p-tKB-d8nwoPsOl6">
																		<Head>
																			<meta name="color-scheme" content={settings.paletteMode} />
																			<meta name="theme-color" content={theme.palette.neutral[900]} />
																		</Head>
																		<RTL direction={settings.direction}>
																			<OfflineDialog />
																			<AppUpdateChecker />
																			<CssBaseline />
																			{showSlashScreen ? <SplashScreen /> : <>
																					{getLayout(<Component {...pageProps} />)}
																					<SettingsButton onClick={settings.handleDrawerOpen} />
																					<SettingsDrawer canReset={settings.isCustom} onClose={settings.handleDrawerClose} onReset={settings.handleReset} onUpdate={settings.handleUpdate} open={settings.openDrawer} values={{
                                  colorPreset: settings.colorPreset,
                                  contrast: settings.contrast,
                                  direction: settings.direction,
                                  paletteMode: settings.paletteMode,
                                  responsiveFontSizes: settings.responsiveFontSizes,
                                  stretch: settings.stretch,
                                  layout: settings.layout,
                                  navColor: settings.navColor
                                }} />
																				</>}
																			<Toaster />
																		</RTL>
																	</ErrorBoundary>
																</ThemeProvider>;
                      }}
													</SettingsConsumer>
												</SettingsProvider>}
										</AuthConsumer>
									</AuthProvider>
								</LocalizationProvider>
							</AppProvider>
						</QueryProvider>}
				</I18nConsumer>
			</I18nProvider>
		</CacheProvider>;
};
export default CustomApp;