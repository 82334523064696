import { GraphQLClient } from 'graphql-request';
import { RequestMiddleware, Response } from 'graphql-request/build/esm/types';
import { ClientOptions, createClient } from 'graphql-sse';
import toast from 'react-hot-toast';

import { getState } from '~/store';

import { browserStorage } from './localforage';

const requestMiddleware: RequestMiddleware = async (request) => {
	const authHeaders = await getAuthHeaders();
	return { ...request, headers: { ...request.headers, ...authHeaders } };
};

const responseMiddleware = async (response: Response<unknown> | Error) => {
	if (response?.['response']?.['errors'] && response?.['response']?.['errors']?.length) {
		const messages = response['response']['errors']
			.map((e) => e.message)
			.filter(Boolean)
			.join('\n');
		toast.error(messages || '🤧 Something went wrong');
	}
};

export const apiGqlClient = new GraphQLClient(process.env.API_GQL_URL!, {
	requestMiddleware,
	responseMiddleware,
});

const getAuthHeaders = async () => {
	const obj = {} as Record<string, string>;

	const { companyId, token: _token } = getState();
	const token = _token || (await browserStorage.token.get());

	if (token && obj['Authorization'] === undefined) obj['Authorization'] = `Bearer ${token}`;
	if (companyId) obj['x-served-company-id'] = companyId;
	obj['x-served-web-app-signature'] = new Date().getTime().toString();

	return obj;
};

export const getApiGqlWsClient = () => {
	return createClient({
		url: process.env.API_GQL_WS_URL!,
		retryAttempts: Infinity,
		retry: () => new Promise((resolve) => setTimeout(resolve, 2000)),
		headers: getAuthHeaders,
		on: getWsClientLogger(),
	});
};

let reconnectToastId: string | undefined;

export const getWsClientLogger = (): ClientOptions['on'] => {
	return {
		connected: (reconnected) => {
			if (reconnected && reconnectToastId) toast.dismiss(reconnectToastId);
		},
		connecting: (reconnecting) => {
			if (reconnecting && !reconnectToastId) {
				reconnectToastId = toast.loading('Lost real time connection...', {
					duration: Infinity,
				});
			}
		},
		message: (..._e) => {},
	};
};
