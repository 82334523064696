import Head from 'next/head';
import type { FC } from 'react';
interface SeoProps {
  title?: string;
}
export const Seo: FC<SeoProps> = props => {
  const {
    title
  } = props;
  const fullTitle = title ? title + ' | Served ERP' : 'Served ERP';
  return <Head data-sentry-element="Head" data-sentry-component="Seo" data-sentry-source-file="seo.tsx">
			<title>{fullTitle}</title>
		</Head>;
};