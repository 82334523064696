import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { browserStorage } from '~/libs/localforage';
import { I18nContext, initialState } from './i18n-context';
import type { FC, ReactNode } from 'react';
import type { State } from './i18n-context';
enum ActionType {
  INITIALIZE = 'INITIALIZE',
}
type InitializeAction = {
  type: ActionType.INITIALIZE;
};
type Action = InitializeAction;
type Handler = (state: State, action: any) => State;
const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (): State => ({
    isInitialized: true
  })
};
const reducer = (state: State, action: Action): State => handlers[action.type] ? handlers[action.type](state, action) : state;
interface I18nProviderProps {
  children: ReactNode;
}
export const I18nProvider: FC<I18nProviderProps> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    i18n
  } = useTranslation();
  const initialize = useCallback(async (): Promise<void> => {
    const language = await browserStorage.language.get();
    if (language) {
      await i18n.changeLanguage(language);
    }
    dispatch({
      type: ActionType.INITIALIZE
    });
  }, [dispatch]);
  useEffect(() => {
    initialize();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return <I18nContext.Provider value={state} data-sentry-element="unknown" data-sentry-component="I18nProvider" data-sentry-source-file="i18n-provider.tsx">{children}</I18nContext.Provider>;
};