import localforage from 'localforage';

import { LOCAL_STORAGE_KEYS } from '~/configs';

export const browserStorage = {
	token: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.TOKEN),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.TOKEN, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.TOKEN),
	},
	settings: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.SETTINGS),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.SETTINGS, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.SETTINGS),
	},
	language: {
		get: () => localforage.getItem<string>(LOCAL_STORAGE_KEYS.LANGUAGE),
		set: (str: any) => localforage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, str),
		remove: () => localforage.removeItem(LOCAL_STORAGE_KEYS.LANGUAGE),
	},
};
