import { useCallback, useEffect, useReducer } from 'react';
import toast from 'react-hot-toast';
import { browserStorage } from '~/libs/localforage';
import { useLogin, useLoginSilent } from '~/queries/useLogin';
import { useResetApp } from '~/store';
import { AuthContext, initialState } from './auth-context';
import type { FC, ReactNode } from 'react';
import type { State } from './auth-context';
enum ActionType {
  LOADING = 'LOADING',
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
}
type LoadingAction = {
  type: ActionType.LOADING;
  payload: {
    isLoading: boolean;
  };
};
type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    isLoading?: boolean;
  };
};
type SignInAction = {
  type: ActionType.SIGN_IN;
};
type SignOutAction = {
  type: ActionType.SIGN_OUT;
};
type Action = LoadingAction | InitializeAction | SignInAction | SignOutAction;
type Handler = (state: State, action: any) => State;
const handlers: Record<ActionType, Handler> = {
  LOADING: (state: State, action: InitializeAction): State => ({
    ...state,
    ...action.payload
  }),
  INITIALIZE: (state: State, action: InitializeAction): State => ({
    ...state,
    ...action.payload,
    isInitialized: true
  }),
  SIGN_IN: (state: State): State => ({
    ...state,
    isAuthenticated: true
  }),
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false
  })
};
const reducer = (state: State, action: Action): State => handlers[action.type] ? handlers[action.type](state, action) : state;
interface AuthProviderProps {
  children: ReactNode;
}
export const AuthProvider: FC<AuthProviderProps> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const login = useLogin();
  const loginSilent = useLoginSilent();
  const resetApp = useResetApp();
  const initialize = useCallback(async (): Promise<void> => {
    try {
      const accessToken = await browserStorage.token.get();
      if (accessToken) {
        dispatch({
          type: ActionType.LOADING,
          payload: {
            isLoading: true
          }
        });
        await loginSilent.mutateAsync();
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            isLoading: false
          }
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            isLoading: false
          }
        });
      }
    } catch (err) {
      console.log('🚀 ~ initialize ~ err:', err);
      toast.error('Something went wrong!');
      window.$queryClient?.clear?.();
      browserStorage.token.remove();
      resetApp();
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          isLoading: false
        }
      });
    }
  }, [dispatch]);
  useEffect(() => {
    initialize();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  const signIn = useCallback(async (username: string, password: string): Promise<void> => {
    await login.mutateAsync({
      input: {
        username,
        password
      }
    });
    dispatch({
      type: ActionType.SIGN_IN
    });
  }, [dispatch]);
  const signOut = useCallback(async (): Promise<void> => {
    dispatch({
      type: ActionType.LOADING,
      payload: {
        isLoading: true
      }
    });
    dispatch({
      type: ActionType.SIGN_OUT
    });
    window.$queryClient?.clear?.();
    await browserStorage.token.remove();
    resetApp();
    dispatch({
      type: ActionType.LOADING,
      payload: {
        isLoading: false
      }
    });
  }, [dispatch]);
  return <AuthContext.Provider value={{
    ...state,
    signIn,
    signOut
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="auth-provider.tsx">
			{children}
		</AuthContext.Provider>;
};