import { createContext } from 'react';

export interface State {
	isInitialized: boolean;
	isAuthenticated: boolean;
	isLoading: boolean;
}

export const initialState: State = {
	isAuthenticated: false,
	isInitialized: false,
	isLoading: false,
};

export interface AuthContext extends State {
	signIn: (username: string, password: string) => Promise<void>;
	signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContext>({
	...initialState,
	signIn: () => Promise.resolve(),
	signOut: () => Promise.resolve(),
});
