import { useMutation } from '@tanstack/react-query';

import { apiGqlClient } from '~/libs/gql';
import { browserStorage } from '~/libs/localforage';
import { useSetToken } from '~/store';
import { graphql } from '~/types/__generated/gql';
import { LoginMutation, LoginMutationVariables, LoginSilentMutation } from '~/types/__generated/gql/graphql';
import { useGetCurrentCompany } from '../useGetCurrentCompany';
import { useMe } from '../useMe';

const queryLogin = /* GraphQL */ `
	mutation Login($input: LoginInput!) {
		login(input: $input) {
			token
		}
	}
`;

const queryLoginSilent = /* GraphQL */ `
	mutation LoginSilent {
		loginSilent {
			token
		}
	}
`;

export type LoginResults = LoginMutation['login'] | LoginSilentMutation['loginSilent'];

export const useLogin = () => {
	const { refetch: refetchCompany } = useGetCurrentCompany(false);
	const { refetch: refetchMe } = useMe(false);
	const setToken = useSetToken();

	return useMutation({
		mutationFn: (variables: LoginMutationVariables) =>
			apiGqlClient.request(graphql(queryLogin), variables).then((res) => res.login),
		onSuccess: async (data) => {
			await Promise.all([browserStorage.token.set(data.token), setToken(data.token)]);
			await Promise.all([refetchCompany(), refetchMe()]);
		},
	});
};

export const useLoginSilent = () => {
	const { refetch: refetchCompany } = useGetCurrentCompany(false);
	const { refetch: refetchMe } = useMe(false);
	const setToken = useSetToken();

	return useMutation({
		mutationFn: () => apiGqlClient.request(graphql(queryLoginSilent)).then((res) => res.loginSilent),
		onSuccess: async (data) => {
			await Promise.all([browserStorage.token.set(data.token), setToken(data.token)]);
			await Promise.all([refetchCompany(), refetchMe()]);
		},
	});
};
