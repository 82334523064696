import { ROLE } from '~/types/__generated/gql/graphql';

export const LOCAL_STORAGE_KEYS = {
	TOKEN: 'served:web:token',
	SETTINGS: 'served:web:settings',
	LANGUAGE: 'served:web:language',
};

export const ROLE_TITLE = {
	[ROLE.SUPER_ADMIN]: 'Super Admin',
	[ROLE.ADMIN]: ' Admin',
	[ROLE.STAFF]: 'Staff',
};
