globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"c4ee0135485773cea35d99a05d21a05f1f108eb6"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { isRelease } from '~served/utils';

if (isRelease()) {
	Sentry.init({
		dsn: 'https://847befebf52fa73912a4bcaf6b7264db@o1365533.ingest.us.sentry.io/4508017683202048',

		// Add optional integrations for additional features
		integrations: [Sentry.replayIntegration()],

		// Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
		tracesSampleRate: 1,

		// Define how likely Replay events are sampled.
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// Define how likely Replay events are sampled when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,
	});
}
