import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import type { FC } from 'react';
export const SplashScreen: FC = () => <Box sx={{
  alignItems: 'center',
  backgroundColor: 'background.paper',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  justifyContent: 'center',
  left: 0,
  p: 3,
  position: 'fixed',
  top: 0,
  width: '100vw',
  zIndex: 1400
}} data-sentry-element="Box" data-sentry-component="SplashScreen" data-sentry-source-file="splash-screen.tsx">
		<img src="/favicon.png" width="128px" />
		<CircularProgress data-sentry-element="CircularProgress" data-sentry-source-file="splash-screen.tsx" />
	</Box>;