import { Client } from 'graphql-sse';
import { FC, ReactNode, useEffect } from 'react';
import { getApiGqlWsClient } from '~/libs/gql';
import { subscribeToSystemHealth } from '~/queries/useSubscribeToSystemHealth';
export const AppProvider: FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  useWsSubscriptions();
  return children;
};
let client: Client | undefined;
const useWsSubscriptions = () => {
  useEffect(() => {
    async function start() {
      if (client) client.dispose();
      client = getApiGqlWsClient();

      /**
       * put all subscriptions here
       * so that when params change, the client is re-initialized with all the subscriptions
       */

      subscribeToSystemHealth(client);
    }
    start();
    return () => {
      if (client) client.dispose();
    };
  }, []);
};